import http from '@/utils/request.js'
//课件详情 --- 未购买
export const noPayInfo = (params) => {
    return http.post('/w/course/ware/non/pay/info', params, { domainName: 'api' })
}
//课件详情 --- 已购买
export const payInfo = (params) => {
    return http.post('/w/course/class/ware/pay/info', params, { domainName: 'api' })
}
//获取视频凭证
export const playAuth = (params) => {
    return http.post('/a/course/video/get/play/auth', params, { domainName: 'api' })
}
//获取视频地址
export const playInfo = (params) => {
    return http.post('/a/course/video/get/play/info', params, { domainName: 'api' })
}
//检验视频锁是否存在
export const lock = (params) => {
  return http.post('/w/course/play/check/lock', params, { domainName: 'api' })
}
//播放视频加锁
export const addLock = (params) => {
    return http.post('/w/course/play/add/lock', params, { domainName: 'api' })
}
//新增播放记录
export const recordLog = (params) => {
    return http.post('/w/course/play/record', params, { domainName: 'api' })
}
//播放视频手动释放锁
export const releaseLock = (params) => {
    return http.post('/w/course/play/release/lock', params, { domainName: 'api' })
}

/**
 * 笔记
 */
// 获取笔记列表
export const GetNotesList = (params) => {
    return http.post('/w/course/eduNotes/getPage', params, { domainName: 'api' });
  }
// 添加笔记
export const AddNotes = (params) => {
  return http.post('/w/course/eduNotes/add', params, { domainName: 'api' });
}
// 添加笔记
export const EditNotes = (params) => {
  return http.post('/w/course/eduNotes/upNotes', params, { domainName: 'api' });
}
// 删除笔记
export const DelNotes = (params) => {
  return http.post('/w/course/eduNotes/del', params, { domainName: 'api' });
}

/**
 * 留言
 */
// 添加留言
export const GetkjListLeave = (params) => {
  return http.post('/w/course/cmuLeave/kjListLeave', params, { domainName: 'api' });
}
// 添加留言
export const AddkjListLeave = (params) => {
  return http.post('/w/course/cmuLeave/add', params, { domainName: 'api' });
}
// 留言举报
export const AddkjListLeaveReport = (params) => {
  return http.post('/w/course/cmuLeaveReport/add', params, { domainName: 'api' });
}

/**
 * 视频投诉
 */
export const AddVideoReport = (params) => {
  return http.post('/w/course/videoSuggest/add', params, { domainName: 'api' });
}