<template>
  <div class="bg">
    <div class="wrapper">
      <div class="floor-t">
        <div class="floor-t-l">
          <img style="width: 500px; height: 280px;" :src="`/_fid/` + wareInfo.cover" alt="">
        </div>
        <div class="floor-t-r">
          <div class="floor-t-r-title">{{wareInfo.name}}</div>
          <div class="floor-t-r-hole">
            <div>课件：{{wareInfo.pitchNumber}}</div>
            <div class="spa">45学时</div>
            <div class="spa">{{wareInfo.totalScore}}学分</div>
          </div>
          <div class="floor-t-r-letter line-text-3">{{wareInfo.introduction}}</div>
          <div class="floor-t-r-label">
            <div class="label-i">网络安全</div>
            <div class="label-i">有认证</div>
            <div class="label-i">课程标签</div>
          </div>
          <div class="floor-t-r-position">
            <div>
              <icon-user style="color: #323233; margin-right: 8px; font-size: 18px;" />
              <span>{{wareInfo.studyNumber}}</span>
              <!-- <icon-star-fill style="color: #323233; margin-left: 20px; margin-right: 8px; font-size: 18px;" />
              <span></span> -->
            </div>
            <div class="btn" @click="joinStudy">{{state == 1?'继续学习':'加入学习'}}</div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px; display: flex; align-items: flex-start;">
        <div class="courseware-left">
          <div class="tab">
            <div class="tab-pane" @click="handleTrigger(index)" :class="index == obj.active ? 'active': ''" v-for="(item, index) in obj.tab" :key="index">{{item}}</div>
          </div>
          <div class="floor-b-a" v-if="obj.active == 0">
            {{wareInfo.intro}}
          </div>
          <div class="floor-b-b" v-if="obj.active == 1">
            <div> 
              <ul style="margin-bottom:10px;">
                <li>章节名称</li>
                <li>时长</li>
              </ul>
              <ul v-for="(item, index) in obj.collapseList" :key="index">
                <li>{{item.name}}</li>
                <li>{{item.duration}}</li>
              </ul>
            </div>
            <!-- <a-collapse expand-icon-position="right" :default-active-key="[0]" :bordered="false" v-if="chapterType == 2">
              <template #expand-icon="{ active }">
                <icon-up v-if="active"/>
                <icon-down v-else/>
              </template>
              <a-collapse-item :header="item.name" v-for="(item, index) in obj.collapseList" :key="index">
                <div class="collapse-item" v-for="(items, index) in item.children" :key="index">
                  <div class="collapse-item-title line-text-1">{{items.name}}</div>
                  <div class="collapse-item-time">{{items.duration}}</div>
                </div>
              </a-collapse-item>
            </a-collapse> -->
          </div>
          <div class="floor-b-c" v-if="obj.active == 2">
            <!-- :pagination="state.pagination" @page-change="change" -->
            <a-table :bordered="false"  :data="wareInfo.teachers" >
              <template #columns>
              <!-- <a-table-column title="头像" data-index="avatar">
                  <template #cell="{ record }">
                    <img style="width: 50px; height: 50px; border-radius: 50%" :src="record.avatar" alt="" srcset="">
                  </template>
              </a-table-column> -->
              <a-table-column title="姓名" data-index="name"/>
              <a-table-column title="简介" data-index="intro">
                  <template #cell="{record}">
                    <a-tooltip :content="record.intro">
                      <div class="line-text-2" style="">{{record.intro}}</div>
                    </a-tooltip>
                  </template>
              </a-table-column>
              <a-table-column title="单位" data-index="company"/>
              <a-table-column title="职称" data-index="title"/>
            </template>  
            </a-table>
          </div>
        </div>
        <div class="courseware-right">
          <div class="title">授课老师</div>
          <div class="courseware-right-container" v-for="(item,index) in wareInfo.teachingTeachers" :key="index">
            <div style="margin-right: 18px;">
              <img :src="item.avatar" alt="">
            </div>
            <div class="courseware-right-text">
              <div style="color: #323233; font-weight: 700; font-size: 16px; margin-bottom: 10px;">{{item.name}}</div>
              <div style="color: #7B7B7B; font-weight: 400; font-size: 12px; margin-bottom: 10px;">{{item.company}}</div>
              <div style="color: #323233; font-weight: 400; font-size: 14px;">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="visible" title=" " @ok="handleOk" @cancel="handleCancel">
      <div v-if="visibleValue == 1">请先完成登录！</div>
      <div v-if="visibleValue == 2">请先课程报名后再加入课件!</div>
      <div v-if="visibleValue == 3">确定加入该课件？</div>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, toRefs,ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import * as wareApi from '@/api/ware';

    const router = useRouter()
    const route = useRoute()
    var token = localStorage.getItem('webtoken')
    const courseId = route.query.courseId;
    const wareId = route.query.id;
    const state = route.query.state;
    const signState = route.query.signState;

    //课件详情
    const wareInfo = ref({});
    const collapseList = ref([]);
    const getWareInfo = () => {
      if(state == 1){
         wareApi.payInfo({courseId:courseId,id:wareId}).then(res => {
            if(res.result != null){
              wareInfo.value = res.result;
              obj.collapseList = res.result.chapters;
            }
          })
      }else{
        wareApi.noPayInfo({courseId:courseId,id:wareId}).then(res => {
         if(res.result != null){
            wareInfo.value = res.result;
            obj.collapseList = res.result.chapters;
         }
       })
      }
       
    };
    getWareInfo();
    //加入学习
    const visible = ref(false);
    const visibleValue = ref(0)
    const joinStudy = () => {
      if(state == 1){
        if(!token){
           visible.value = true;
           visibleValue.value = 1;
         }else{
           router.push({ path: '/course/courseware_b',query:{id: wareId,courseId:courseId}})
         }  
      }else{
        visible.value = true;
         if(!token){
           visibleValue.value = 1;
         }else{
           if(signState !== 3){
              visibleValue.value = 2; 
           }else{
              visibleValue.value = 3; 
           }
         }
         
      }
    };
    const handleOk = () =>{
      if(visibleValue.value == 1 ){
         router.push({ path: '/login',query:route.query})
      }else if(visibleValue.value == 2){
         router.push({ path: '/course/list',query:{id:courseId}})
      }else if(visibleValue.value == 3){
        router.push({ path: '/course/courseware_b',query:{courseId:courseId,id: wareId}})
      }
    };
    const handleCancel = () =>{
        visible.value = false;
    };
    const obj = reactive({
      collapseList: [
        {
          title: '第一章 关于新时期加快推进交通新基建第一章',
          children: [
            { title: '1 新时期加快推进交通新时期', time: '23分20秒' },
            { title: '2 新时期加快推进交通新时期', time: '23分20秒' },
            { title: '2 新时期加快推进交通新时期', time: '23分20秒' }
          ]
        },
        {
          title: '第二章 关于新时期加快推进交通新基建第一章',
          children: [
            { title: '1 新时期加快推进交通新时期', time: '23分20秒' },
            { title: '2 新时期加快推进交通新时期', time: '23分20秒' },
            { title: '2 新时期加快推进交通新时期', time: '23分20秒' }
          ]
        },
        {
          title: '第三章 关于新时期加快推进交通新基建第一章',
          children: [
            { title: '1 新时期加快推进交通新时期', time: '23分20秒' },
            { title: '2 新时期加快推进交通新时期', time: '23分20秒' },
            { title: '2 新时期加快推进交通新时期', time: '23分20秒' }
          ]
        }
      ],
      tab: ['课件介绍', '课程目录', '课件讲师'],
      active: 0,
      pagination: {
        pageItemStyle: { border: '1px solid rgba(0,0,0,0.1500)', borderRadius: '2px' },
        activePageItemStyle: { backgroundColor: 'transparent', border: '1px solid #207AFF', color: '#207AFF' },
        defaultCurrent: 1,
        pageSize: 10,
        total: 5
      },
    })
    const handleTrigger = (index) => {
      obj.active = index
    }
    const change = (val) => {
      console.log('第几页' + val)
    }
    const btn = () => {
    }
</script>

<style lang="less" scoped>
.bg {
  background: #F7F6FA;
  padding: 20px 0;
  .floor-t {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px;
    display: flex;
    .floor-t-l {
      margin-right: 20px;
    }
    .floor-t-r {
      flex: 1;
      position: relative;
      .floor-t-r-title {
        padding-top: 2px;
        font-size: 20px;
        color: #323233;
        font-weight: 700;
      }
      .floor-t-r-hole {
        margin: 16px 0;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 12px;
        color: #207AFF;
        .spa {
          margin-left: 20px;
          padding: 0 12px;
          height: 20px;
          display: flex;
          align-items: center;
          background: rgba(79,23,168,0.1000);
          box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.0400);
          border-radius: 24px;
          color: #207AFF;
          // margin-right: 20px;
        }
      }
      .floor-t-r-letter {
        font-size: 14px;
        color: #7B7B7B;
        line-height: 22px;
      }
      .floor-t-r-label {
        margin-top: 16px;
        display: flex;
        .label-i + .label-i {
          margin-left: 10px;
        }
        .label-i {
          height: 22px;
          background: #FFF7E6;
          border-radius: 2px;
          border: 1px solid #FFD591;
          padding: 0 10px;
          color: #D46B08;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .floor-t-r-position {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #323233;
        font-size: 14px;
        .btn {
          cursor: pointer;
          width: 206px;
          height: 48px;
          background: #207AFF;
          border-radius: 4px;
          font-size: 18px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.courseware-left {
  width: 780px;
  margin-right: 20px;
  background: #fff;
  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #e9e9e9;
    .tab-pane {
      display: flex;
      align-items: center;
      margin: 0 24px;
      height: 60px;
      color: #474747;
      cursor: pointer;
      border-bottom: 3px solid transparent;
    }
    .active {
      color: #207AFF;
      font-weight: 600;
      border-bottom: 3px solid #207AFF;
    }
  }
  .floor-b-a {
    padding: 20px 30px;
    min-height: 300px;
  }
  .floor-b-b {
    padding: 10px 20px 20px;
    :deep(.arco-collapse-item-header) {
      background-color: #F9F9F9;
      .arco-collapse-item-header-title {
        color: #323233;
        font-weight: 700;
        font-size: 14px;
      }
    }
    :deep(.arco-collapse-item-content) {
      background: #fff;
      .collapse-item + .collapse-item {
        border-top: 1px solid #EFEFEF;
      }
      .collapse-item {
        height: 60px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #7B7B7B;
        .collapse-item-title {
          flex: 1;
        }
        .collapse-item-time {
          font-size: 14px;
          margin-left: 30px;
        }
      }
    }
  }
  .floor-b-c {
    padding: 20px;
  }
}
.courseware-right {
  flex: 1;
  background: #fff;
  padding: 0 18px 40px;
  .title {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #E9E9E9;
    font-size: 16px;
    font-weight: 700;
  }
  .courseware-right-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
}
:deep(.arco-pagination){
  display: none;
}
ul {
  display: flex;
  justify-content: space-between;
  padding: 4px 10px;
  line-height: 25px;
}
</style>
